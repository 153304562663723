import { ErrorIdentity } from '@/models/errorResponseBody';
import { Notification } from '@/models/notification';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { AuthResponse } from '@/models/authResponse';
import { getActions, getGetters, getMutations } from '../list-state-mixin';
import { makeRequest } from '@/services/api-request';

/**
 * List state values for the notifications related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 10,
  items: [] as Array<Notification>,
  currentPage: 1,
  total: 0,
  defaultSort: 'aname',
  sort: 'aname', // TODO use sortDirection separately when this is implemented
  isLoading: true,
  selected: [], // Selected notifications.
  search: '',
  errors: [] as Array<ErrorIdentity>,
});

let previousFilterString: string | null = null;
const state = getDefaultState();

const actions = getActions();
const mutations = getMutations();
const getters = getGetters();

mutations.resetState = (moduleState: ListState) => {
  Object.assign(moduleState, getDefaultState());
};

/**
 * Fetches notifications with current page, sort and sort order included.
 *
 * @param {ActionContext} context
 * @return void
 */
actions.fetchItems = async (context: ActionContext<ListState, RootState>) => {
  const apiCurrentPage: number = context.state.currentPage - 1;
  const queryParams: Record<string, string> = {
    per_page: `${context.state.itemsPerPage}`,
    page: `${apiCurrentPage}`,
    sort: context.state.sort,
  };
  if (context.state.search && context.state.search.length > 0) {
    queryParams.search = context.state.search;
  }
  if ((context.state.search ?? '') !== previousFilterString) {
    queryParams.page = '0';
    context.state.currentPage = 1;
  }
  previousFilterString = context.state.search ?? '';
  const url = `/notifications?${new URLSearchParams(queryParams)}`;
  try {
    context.commit('updateLoading', true);
    const authResponse: AuthResponse = await makeRequest('GET', url);
    const totalRecords = authResponse.response.headers.get('X-Total-Count');
    if (totalRecords) {
      context.commit('updateItems', authResponse.body as Array<Notification>);
      context.commit('updateTotal', totalRecords);
      context.commit('updateLoading', false);
    } else {
      context.dispatch('resetToEmpty');
    }
  } catch (error) {
    context.commit('updateErrors', error);
    context.dispatch('resetToEmpty');
    console.error(error);
  }
};

actions.createItem = async (context: ActionContext<ListState, RootState>, notification: Notification) => {
  const url = '/notifications';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = Object.assign({}, notification);
  delete params.id;
  await makeRequest('POST', url, {
    body: JSON.stringify(params),
  });
};

actions.updateItem = async (context: ActionContext<ListState, RootState>, notification: Notification) => {
  const url = `/notifications/${notification.id}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = Object.assign({}, notification);
  delete params.id;
  await makeRequest('PATCH', url, {
    body: JSON.stringify(params),
  });
};

actions.deleteItems = async (context: ActionContext<ListState, RootState>, data: Array<Notification>) => {
  await Promise.all(
    data.map(async (notification: Notification) => {
      const url = `/notifications/${notification.id}`;
      return makeRequest('DELETE', url);
    })
  );
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};

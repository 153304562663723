import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: 'overview',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "scheme-login" */ '../views/common/LoginPage.vue'),
  },
  {
    path: '/overview',
    name: 'overview',
    component: () => import(/* webpackChunkName: "scheme-overview" */ '../views/scheme/OverviewPage.vue'),
  },
  {
    path: '/overview/:schemeSlug',
    name: 'scheme-edit',
    component: () => import(/* webpackChunkName: "scheme-edit" */ '../views/scheme/SchemeEditPage.vue'),
    props: route => ({ schemeSlug: route.params.schemeSlug }),
  },
  {
    path: '/overview/:schemeSlug/sections',
    name: 'sections',
    component: () => import(/* webpackChunkName: "scheme-sections" */ '../views/scheme/SectionsPage.vue'),
    props: route => ({ schemeSlug: route.params.schemeSlug }),
  },
  {
    path: '/overview/:schemeSlug/grades',
    name: 'grades',
    component: () => import(/* webpackChunkName: "scheme-grades" */ '../views/scheme/GradesPage.vue'),
    props: route => ({ schemeSlug: route.params.schemeSlug }),
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import(/* webpackChunkName: "scheme-tags" */ '../views/scheme/TagsPage.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "scheme-notifications" */ '../views/scheme/NotificationsPage.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "scheme-users" */ '../views/common/UsersPage.vue'),
  },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "scheme-users" */ '../views/scheme/MembersPage.vue'),
  },
  {
    path: '/clubs',
    name: 'clubs',
    component: () => import(/* webpackChunkName: "scheme-clubs" */ '../views/scheme/ClubsPage.vue'),
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import(/* webpackChunkName: "scheme-tasks" */ '../views/scheme/TasksPage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "scheme-about" */ '../views/scheme/AboutScheme.vue'),
  },
  {
    path: '/reports/:report?/:schemeSlug?',
    name: 'reports',
    component: () => import(/* webpackChunkName: "scheme-reports" */ '@/components/club/Reports.vue'),
    props: route => ({ report: route.params.report, schemeSlug: route.params.schemeSlug, showProgressReport: false }),
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import(/* webpackChunkName: "scheme-languages" */ '../views/scheme/LanguagesPage.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "scheme-redirect" */ '@/components/common/PostOAuthRedirect.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "common-logout" */ '../views/common/LogoutPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "common-error" */ '@/views/common/ErrorPage.vue'),
  },
  {
    path: '*',
    redirect: '/error',
  },
];

export default routes;

import { ErrorIdentity } from '@/models/errorResponseBody';
import { Tag } from '@/models/tag';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { DataTableOptions } from '@/models/dataTableOptions';
import { AuthResponse } from '@/models/authResponse';
import { getGetters, getMutations } from '../list-state-mixin';
import { makeRequest } from '@/services/api-request';

/**
 * List state values for the tags related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 10,
  items: [] as Array<Tag>,
  currentPage: 1,
  total: 0,
  defaultSort: 'name',
  sort: 'name',
  sortDirection: 'a',
  isLoading: true,
  selected: [], // Selected tags.
  search: '',
  errors: [] as Array<ErrorIdentity>,
});

const state = getDefaultState();

const mutations = getMutations();
const getters = getGetters();

mutations.resetState = (moduleState: ListState) => {
  Object.assign(moduleState, getDefaultState());
};

const actions = {
  resetState: async (context: ActionContext<ListState, RootState>) => {
    context.commit('resetState');
  },
  fetchItems: async (context: ActionContext<ListState, RootState>, schemeSlug?: string) => {
    const queryParams: Record<string, string> = {
      // per_page: `${context.state.itemsPerPage}`,
      // page: `${context.state.currentPage}`,
      sort: context.state.sortDirection + context.state.sort,
    };
    if (context.state.search && context.state.search.length > 0) {
      queryParams.search = context.state.search;
    }
    const url = `/schemes/${schemeSlug}/tags?${new URLSearchParams(queryParams)}`;

    try {
      context.commit('updateLoading', true);
      const authResponse: AuthResponse = await makeRequest('GET', url);
      context.commit('updateItems', authResponse.body as Array<Tag>);
      context.commit('updateTotal', context.state.items.length);
      context.commit('updateLoading', false);
    } catch (error) {
      // this block only fires if the network errors otherwise just get a status back above that isn't ok
      context.dispatch('resetToEmpty');
      console.error(error);
    }
  },
  createItem: async (context: ActionContext<ListState, RootState>, data: { tag: Tag; schemeSlug?: string }) => {
    const url = `/schemes/${data.schemeSlug}/tags`;
    await makeRequest('POST', url, {
      body: JSON.stringify({ name: data.tag.name }),
    });
  },
  updateItem: async (context: ActionContext<ListState, RootState>, data: { tag: Tag; schemeSlug?: string }) => {
    const url = `/schemes/${data.schemeSlug}/tags/${data.tag.id}`;
    await makeRequest('PATCH', url, {
      body: JSON.stringify({ name: data.tag.name }),
    });
  },
  deleteItems: async (
    context: ActionContext<ListState, RootState>,
    data: { tags: Array<Tag>; schemeSlug?: string }
  ) => {
    await Promise.all(
      data.tags.map(async (tag: Tag) => {
        const url = `/schemes/${data.schemeSlug}/tags/${tag.id}`;
        return makeRequest('DELETE', url);
      })
    );
  },
  resetToEmpty: (context: ActionContext<ListState, RootState>) => {
    context.commit('updateItems', []);
    context.commit('updateTotal', 0);
    context.commit('updateLoading', false);
  },
  updateOptions: (context: ActionContext<ListState, RootState>, dataTableOptions: DataTableOptions) => {
    context.commit('updateCurrentPage', dataTableOptions.page);
    context.commit('updateSortOptions', dataTableOptions);
    context.dispatch('fetchItems');
  },
  updatePage: (context: ActionContext<ListState, RootState>, page: number) => {
    context.commit('updateCurrentPage', page);
    context.dispatch('fetchItems');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
